import React, { useContext, useRef, useState, useEffect } from 'react';
import AXIOS from 'axios';
import { useNavigate } from 'react-router-dom';
import fileSize from 'filesize';
import useSWR from 'swr';
import Lottie from 'react-lottie';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import Spinner from 'Components/Spinner';
import {
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  RadioGroup,
  makeStyles,
  withStyles,
  CircularProgress
} from '@material-ui/core';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import { dataFetcher, endpoints, AxiosCancelToken, baseUrl, firebaseCredentials, axios } from 'Api';
import { CloudUploadOutlined } from '@material-ui/icons';
import { ref, update, getDatabase } from 'firebase/database';
import { initializeApp } from 'firebase/app';

import addFileImage from 'Assets/add-file@2x.png';
import noPhotoAvailable from 'Assets/no-image-available.png';
import generatingThumbnail from 'Assets/generating-thumbnail.jpg';
import { ReactComponent as CloseIcon } from 'Assets/close.svg';
import { ReactComponent as BackIcon } from 'Assets/chevron-left.svg';
// import VideoUploaded from 'Assets/video-uploaded.json';
import { addS3VideoDraftContent, addVideoDraftContent, addVideo } from 'Api/apiFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { saveUploadInfo, saveUploadSizes, saveDummy, removeDummy, setAddVideo } from 'store/store';
import { GroupSelector } from '../group-selector';
import { Button } from '../button';
import { Text } from '../text';
import ChooseWhatUpload from './components/ChooseWhatUpload';
import VideoSelect from './components/SelectVideo';
import PhotoSelect from './components/SelectPhoto';
import { Radio, DialogFooter } from './components/Material-reDesign';
import ProgressTracker from './components/ProgressTracker';
import { ContentBox, ContentBoxSection } from './components/ContentBox';
import VideoPreview from './components/VideoPreview';
import PhotoPreview from './components/PhotoPreview';
import InputField from './components/InputField';
import ChooseAlbum from './components/ChooseAlbum';
import AlbumDetails from './components/AlbumDetails';
import AlbumSummary from './components/AlbumSummary';
import LoaderContent from './model-dialog-content/LoaderContent';
import styles from './index.module.css';
import './loader.css';
import UpdateCategoryPlaylist from './model-dialog-content/UpdateCategoryPlaylist';
// eslint-disable-next-line import/order
import useConvertHeicImages from 'hooks/useConvertHeicImages';
import VideoDetails from './components/VideoDetails';
import VideoSummary from './components/VideoSummary';
import { InfoModal } from '../../Pages/private/dashboard/info-modal';

export const SAVE_TYPE = {
  DRAFT: 'draft',
  PUBLISH: 'publish'
};

const defaultVideoDetails = {
  name: undefined,
  duration: undefined,
  size: undefined,
};

const useStyles = makeStyles({
  colorPrimary: {
    background: '#5aa5ef'
  }
});

const Modal = ({ isVisible, onHide, handleUploadModalClose: handleClose, imageFiles }) => {
  const { setHoldTheWeb, setAppSnackbar, subscriptionDetails, setUserDetails, userDetails,
    langText, setPageLoad, addMorePhoto, setaddMorePhoto, isS3UploadComplete, setSingleUpload, isSinglUepload, startingFlowEvent,
    startFlowEvent, setModalVisibility, s3Data, setS3Data, uploadingInProgress, setUploadingInProgress,
    setisAddDraftDone, textOfUpload, settextOfUpload, firebaseDatabase, setUploadContainer,
    setDraftCategoryState, draftCategoryState, setFormData, videoGroup, setVideoGroup,
    setIamUploading, didIOpenModal, setUploadContainer2, autoSignIn } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const uploadPercentages = useSelector((state) => state.upload);
  const dispatch = useDispatch();
  const updateUploadPercentages = (draft_id, data) => {
    dispatch(saveUploadInfo({ [draft_id]: data }));
  };

  const updateUploadSizes = (draft_id, data) => {
    dispatch(saveUploadSizes({ [draft_id]: data }));
  };
  // temp add
  useEffect(() => {
    // const allPropertiesAreNumericOrString = Object.values(uploadPercentages).some((value) => typeof value === 'number' || value === 'Starting Upload' || value === 'Initializing');
    // if (allPropertiesAreNumericOrString) {
    //   setIamUploading(false);
    // } else setIamUploading(true);
    const handleBeforeUnload = (e) => {
      const allPropertiesAreNumericOrString = Object.values(uploadPercentages).some((value) => typeof value === 'number' || value === 'Starting Upload' || value === 'Initializing');
      if (allPropertiesAreNumericOrString) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploadPercentages]);
  // temp end
  const navigate = useNavigate();
  let domain = window.location.href;
  const uploadText = langText?.upload;
  const commonText = langText?.common;
  const visibilityText = langText?.visibility;
  const videoVisibilityOptions = [
    { name: 'All Viewers', value: '2' },
    { name: visibilityText?.privateDescription || 'Only I can view (Private)', value: '1' },
    { name: visibilityText?.groupDescription || 'Choose a group to share with', value: '3' },
  ];
  // const defaultVideoVisibility = videoVisibilityOptions[0].value;
  const defaultVideoVisibility = videoVisibilityOptions[0].value;
  const fileInputRef = useRef(null);

  domain = domain?.replace(/http[s]?:\/\//, '');
  domain = domain?.split('/')[0];

  const [currentStep, setCurrentStep] = useState(undefined);

  const [defaultVideoThumbnail, setDefaultVideoThumbnail] = useState(noPhotoAvailable);
  const [thumbnailType, setThumbnailType] = useState('normal');
  const [blobFile, setBlobFile] = useState([]);
  const [videoDescription, setVideoDescription] = useState('');
  const [videoDetails, setVideoDetails] = useState(defaultVideoDetails);
  const [videoFile, setVideoFile] = useState(undefined);
  const [videoID, setVideoID] = useState(undefined);
  const [videoThumbnails, setVideoThumbnails] = useState([]);
  const [customVideoThumbnails, setCustomVideoThumbnails] = useState([]);
  const customAdded = useRef(false);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoVisibility, setVideoVisibility] = useState(defaultVideoVisibility);
  const [videoIsPublished, setVideoPublishStatus] = useState(false);
  const [videoIsUploaded, setVideoUploadStatus] = useState(false);
  const [isThumbnailCreationFailed, setIsThumbnailCreationFailed] = useState(false);
  const [isThumbnailCreationCompleteFully, setIsThumbnailGenerationCompleteFully] = useState(false);
  const [videoUploadData, setVideoUploadData] = useState(undefined);
  const [fileName, setFileName] = useState('');
  // const [videoCategoryPlaylistData, setVideoCategoryPlaylistData] = useState(undefined);
  const [videoVisibilityData, setVideoVisibilityData] = useState(undefined);
  const [runLoader, setrunLoader] = useState(false);
  const [percentagee, setPercentage] = useState(0);
  const [s3VideoDetails, setS3VideoDetails] = useState({});
  const [uploadedData, setUploadedData] = useState({
    category_id: undefined,
    category_name: undefined,
    description: undefined,
    group_id: undefined,
    playlist_id: undefined,
    publish_date: undefined,
    status: undefined,
    subcategory_id: undefined,
    thumbnails: undefined,
    thumbnail_list: undefined,
    title: undefined,
    token: undefined,
    video_file: undefined,
    video_id: undefined,
    visibility: undefined,
  });

  const [videoUploadingCompleted, setVideoUploadingCompleted] = useState(false);
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);

  const dummyObject = useSelector((state) => state.dummy);

  const [source, setSource] = useState(undefined);
  const [uploadType, setUpdateType] = useState(undefined);
  const [photoFile, setPhotoFile] = useState([]);
  const [chooseAlbumStep, setChooseAlbumStep] = useState(0);
  const [albumTitle, setAlbumTitle] = useState('');
  const [albumDescription, setAlbumDescription] = useState('');
  const [albumThumbnails, setAlbumThumbnails] = useState([]);
  const [customAlbumThumbnails, setCustomAlbumThumbnails] = useState([]);
  const [defaultAlbumThumbnail, setDefaultAlbumThumbnail] = useState(undefined);
  const [albumUploadData, setAlbumUploadData] = useState(undefined);
  const [albumId, setAlbumId] = useState(undefined);
  const [albumVisibility, setAlbumVisibility] = useState(defaultVideoVisibility);
  const [albumGroup, setAlbumGroup] = useState([]);
  const [multiplePhoto, setMultiplePhoto] = useState(undefined);
  const [albumList, setAlbumList] = useState([]);
  const [chooseExistingAlbum, setExistingAlbum] = useState(undefined);
  const [isAnExistingAlbum, updateAnExistingAlbum] = useState(false);
  const [albumSize, setAlbumSize] = useState(undefined);
  const [albumPhotoCount, setAlbumPhotoCount] = useState(undefined);
  const [albumPublished, setAlbumPublishStatus] = useState(false);
  const [contentType, setContentType] = useState(undefined);
  const [albumStatus, setAlbumStatus] = useState(undefined);
  const [conversionStatus, setConversionStatus] = useState(0);
  const [conversionStart, setConversionStart] = useState(false);
  const [thumbnailGenerationStatus, setThumbnailGenerationStatus] = useState(false);
  const [isStorageFull, setStorageFull] = useState(false);
  const [isSelectedFile, setIsSelectedFile] = useState(false);
  const contentDashboard = langText?.contentDashboard;
  const [videoCount, setVideoCount] = useState(undefined);
  const [uniqueid, setUniqueid] = useState('');
  const [uploadChecking, setUploadChecking] = useState('');
  const [draftId, setdraftId] = useState();
  let flow;
  const classes = useStyles();
  const [images, convertingHeic] = useConvertHeicImages(photoFile);
  const [draftApiStatus, setDraftApiStatus] = useState('idle');
  const [uploadedFile, setUploadedFile] = useState();
  localStorage.setItem('percentage', percentagee);
  // end draft
  useEffect(() => {
    source?.cancel('Landing Component got unmounted');
  }, [source]);
  const moveToPreviousStep = () => {
    setCurrentStep((cStep) => cStep - 1);
    // setTimeout(() => { setCurrentStep(currentStep - 1); }, 10);
  };
  const moveToNextStep = () => {
    if ((currentStep + 1) <= 3) {
      setCurrentStep((cStep) => cStep + 1);
      // setTimeout(() => { setCurrentStep(currentStep + 1); }, 10);
    }
  };

  const getToken = () => localStorage.getItem('token');
  const axiosN = AXIOS.create({ baseURL: baseUrl.URL, method: 'POST' });
  axiosN.interceptors.request.use((req) => {
    if (req?.data instanceof FormData) {
      const formData = req?.data;

      if (formData.has('token')) { formData.delete('token'); }

      formData.append('token', getToken());

      return ({ ...req, data: formData });
    }

    return ({ ...req, data: { ...req.data, token: getToken() } });
  });
  const databaseRef = ref(firebaseDatabase, 'status_from_sns');
  const changeFieldFromFirebase = (draft_id, statusString) => {
    const dataToUpdate = {
      [draft_id]: {
        status: statusString,
      },
    };
    update(databaseRef, dataToUpdate);
  };
  const closeToast = (draft_id, message, type) => {
    toast.update(draft_id, { closeButton: false, render: message, type: type === 'success' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR, autoClose: 3000 });
    setUploadContainer((prevUploadContainer) => prevUploadContainer.filter((id) => id !== draft_id));
    setUploadContainer2((prevUploadContainer) => prevUploadContainer.filter((id) => id !== draft_id));
  };
  const deleteAdded = () => {
    // axios
    //   .post(`${baseUrl}/deleteDraftContent`, {
    //     token: getToken(),
    //     draft_id: [s3Data.get('draft_id')],
    //     content_id: []
    //   })
    //   .then((response) => {
    //       changeFieldFromFirebase(s3Data.get('draft_id'), 'DELETED');
    //       updateUploadPercentages(draftId, 'Cancelled');
    //       closeToast(s3Data.get('draft_id'), 'Upload has been canceled by user', 'error');
    //       setIsSelectedFile(false);
    //       return null;
    //   })
    //   .catch((error) => {
    //     console.error('Error deleting items:', error);
    //   });
      changeFieldFromFirebase(s3Data.get('draft_id'), 'DELETED');
      updateUploadPercentages(draftId, 'Cancelled');
      closeToast(s3Data.get('draft_id'), 'Upload has been canceled by user', 'error');
      setIsSelectedFile(false);
      return null;
  };

  // const dataFetcher2 = (url, data, cancelToken) => axios({
  //   url,
  //   ...data && { data },
  //   onUploadProgress: (ProgressEvent) => {
  //     const { loaded, total } = ProgressEvent;
  //     const percentage = Math.floor((loaded * 100) / total);
  //     setPercentage(percentage);
  //     if (percentage < 98) {
  //       setVideoUploadPercentage(percentage);
  //     } else {
  //       setVideoUploadPercentage(98);
  //     }
  //   },
  //   ...cancelToken && { cancelToken },

  // }).then((res) => res.data);

  const { isValidating: gettingThumbnail, mutate: getThumbnail } = useSWR([
    endpoints.getThumbnails, videoID,
  ], {
    fetcher: (url, content_id) => dataFetcher(url, { content_id }),
    onSuccess: ({ success, message, videos }) => {
      if (success && videos.status) {
        if (videos?.thumbnails?.length === 0) {
          // If thumbnails array is empty, call the mutation function after 3 seconds
          setTimeout(() => {
            getThumbnail();
          }, 5000);
        } else {
          setThumbnailGenerationStatus(false);
          setVideoThumbnails([...videoThumbnails, ...videos?.thumbnails?.slice(0, 5)]);
        }
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const { isValidating: gettingConvertionStatus, mutate: getConversionStatus } = useSWR([
    endpoints.getVideoStatus, videoID,
  ], {
    fetcher: (url, video_id) => dataFetcher(url, { video_id }),
    onSuccess: ({ success, message, videos }) => {
      if (success) {
        setConversionStatus(videos[0].progress);
        if (videos[0].progress === 100) {
          setTimeout(() => { setConversionStart(false); }, 1000);
        }
      } else {
        // setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });
  const { isValidating: uploadingVideo, mutate: uploadVideoContent } = useSWR([
    endpoints.addVideoContent, videoUploadData, source?.token], {
    fetcher: (url, formData, uniqueCancelToken) => dataFetcher(url, formData, uniqueCancelToken),
    onSuccess: ({ success, video_id, suggestedThumbnails, message, has_conversion }) => {
      if (success) {
        // moveToNextStep();
        if (!videoIsUploaded) {
          // setVideoThumbnails(suggestedThumbnails);
          // setDefaultVideoThumbnail(suggestedThumbnails[0]);
          setVideoID(video_id);
          setVideoUploadStatus(true);
        }
        setThumbnailGenerationStatus(true);
        if (has_conversion) {
          setConversionStart(true);
        }
        // setTimeout(() => {
        //   getThumbnail();
        // }, 5000);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 500);
      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong!!' });
      if (responseData?.message === 'Storage Space Exceeded') {
        handleClose();
        setTimeout(() => { navigate(Pathname.upgradePlan); }, 500);
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 500);

      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
  });

  const { isValidating: uploadingS3Video, mutate: uploadS3Video } = useSWR([
    endpoints.addS3VideoContent, s3Data, source?.token], {
    fetcher: (url, formData, uniqueCancelToken) => dataFetcher(url, formData, uniqueCancelToken),
    onSuccess: ({ success, video_id, suggestedThumbnails, message, has_conversion }) => {
      if (success) {
        // moveToNextStep();
        if (!videoIsUploaded) {
          // setVideoThumbnails(suggestedThumbnails);
          // setDefaultVideoThumbnail(suggestedThumbnails[0]);
          setVideoID(video_id);
          setVideoUploadStatus(true);
        }
        setThumbnailGenerationStatus(true);
        if (has_conversion) {
          setConversionStart(true);
        }
        // setTimeout(() => {
        //   getThumbnail();
        // }, 5000);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 500);
      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong!!' });
      if (responseData?.message === 'Storage Space Exceeded') {
        handleClose();
        setTimeout(() => { navigate(Pathname.upgradePlan); }, 500);
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 500);

      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
  });

  useEffect(() => {
    let interval;
    if (conversionStart) {
      interval = setInterval(() => {
        getConversionStatus();
      }, 5000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [conversionStart]);

  // useEffect(() => {
  //   let interval;
  //   if (thumbnailGenerationStatus) {
  //     interval = setInterval(() => {
  //       getThumbnail();
  //     }, 5000);
  //   } else {
  //     clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);
  // }, [thumbnailGenerationStatus]);

  const { isValidating: uploadingVisibility, mutate: updateVisibilityContent } = useSWR([
    endpoints.addVideoContent, videoVisibilityData,
  ], {
    fetcher: (url, formData) => dataFetcher(url, formData),
    onSuccess: ({ success, message }) => {
      if (success) {
        setUserDetails({
          ...userDetails,
          totalVideoUploaded: userDetails.totalVideoUploaded > 0
            ? userDetails.totalVideoUploaded + 1 : 1,
          totalContentUploaded: userDetails.totalContentUploaded > 0
            ? userDetails.totalContentUploaded + 1 : 1
        });
        setVideoPublishStatus(true);
        if (conversionStart) {
          onHide();
          setTimeout(() => {
            navigate(Pathname.processingInBackGround);
            window.location.replace(Pathname.yourVideos + '?showModal=1'); // eslint-disable-line prefer-template
          }, 500);
        } else {
          setTimeout(() => {
            onHide();
            // eslint-disable-line prefer-template
            window.location.replace(Pathname.yourVideos + '?showModal=1'); // eslint-disable-line prefer-template
          },
            3000);
        }
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });
  // Call moveToNextStep() when the API call starts or is in progress
  useEffect(() => {
    if (uploadingVideo) {
      moveToNextStep();
    }
  }, [uploadingVideo]);
  const { isValidating: updatingAlbum, mutate: addEditAlbum } = useSWR([
    endpoints.addEditAlbum, albumTitle, albumDescription, source?.token], {
    fetcher: (url, title, description, uniqueCancelToken) => dataFetcher(url,
      { title: albumTitle, description: albumDescription }, uniqueCancelToken),
    onSuccess: ({ success, album_id, message }) => {
      setLoader(false);
      if (success) {
        setAlbumId(album_id);
        setChooseAlbumStep(4);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong!!' });
    },
  });
  const uploadImageByBatch = async (data) => {
      const response = await axios.post(endpoints.addPhotoContent, data);
      return response;
  };
  const uploadByPartPhotos = async () => {
    const totalPhotos = photoFile.length;
    let batchSize = 5;
    if (totalPhotos > 100 && totalPhotos < 500) {
      batchSize = Math.ceil(totalPhotos / 20);
    } else if (totalPhotos > 499 && totalPhotos < 2000) {
      batchSize = Math.ceil(totalPhotos / 50);
    } else if (totalPhotos > 1999) {
      batchSize = Math.ceil(totalPhotos / 100);
    }
    let totalSize = 0;
    for (let i = 0; i < totalPhotos; i += 1) {
      const photo = photoFile[i];
      totalSize += photo.file.size;
    }
    let currentIndex = 0;
    let successfulUploadCount = 0;
    let successfulUploadSizes = 0;
    updateUploadPercentages(albumId, 'Loading');
    updateUploadSizes(albumId, 'Loading');
    async function uploadNextBatch() {
      if (currentIndex >= totalPhotos) {
        return;
      }
      const batch = photoFile.slice(currentIndex, currentIndex + batchSize);
      const batchFormData = new FormData();
      batchFormData.append('album_id', albumId);
      let size = 0;
      batch.forEach((photo) => {
        batchFormData.append('photo_file[]', photo.file);
        size += photo.file.size;
      });
      try {
        await uploadImageByBatch(batchFormData);
        currentIndex += batchSize;
        successfulUploadCount += batch.length;
        successfulUploadSizes += size;
        updateUploadPercentages(albumId, `${successfulUploadCount}/${totalPhotos}`);
        updateUploadSizes(albumId, `${(successfulUploadSizes / (1024 * 1024)).toFixed(1) > 1000 ? `${(successfulUploadSizes / (1024 * 1024)).toFixed(1)} GB` : `${(successfulUploadSizes / (1024 * 1024)).toFixed(1)} MB`}/${(totalSize / (1024 * 1024)).toFixed(2) > 1000 ? `${(totalSize / (1024 * 1024)).toFixed(2)} GB` : `${(totalSize / (1024 * 1024)).toFixed(2)} MB`}`);
        await uploadNextBatch();
      } catch (error) {
        console.error('Error uploading batch:', error);
      }
    }
    await uploadNextBatch();
    updateUploadPercentages(albumId, null);
    const totalSizeMB = (totalSize / (1024 * 1024)).toFixed(2);
const totalSizeGB = (totalSizeMB / 1024).toFixed(2);
const sizeString = totalSizeGB > 1 ? `${totalSizeGB} GB` : `${totalSizeMB} MB`;
updateUploadSizes(albumId, sizeString);
  };

  const { isValidating: addingPhotos, mutate: addPhotoContent } = useSWR([
    endpoints.addPhotoContent, albumUploadData], {
    fetcher: (url, formData) => dataFetcher(url, formData),
    onSuccess: ({ success, total_count, has_subsription, message }) => {
      if (success) {
        settextOfUpload('Done');
        uploadByPartPhotos();
        if (has_subsription) {
          if (total_count) {
            setUserDetails({
              ...userDetails,
              totalContentUploaded: userDetails.totalContentUploaded > 0
                ? userDetails.totalContentUploaded + 1 : 1
            });
          }
          window.sessionStorage.setItem('tabTitle', 1);
          setTimeout(() => {
            handleClose();
           setaddMorePhoto(false);
            setPageLoad(true);
            localStorage.setItem('isFromUpload', true);
            if (userDetails.totalContentUploaded === 0 && !userDetails?.isSubscription) {
              window.location.replace(`${Pathname.yourVideos}?showModal=1`);
            } else {
              navigate(Pathname.yourVideos);
            }
          }, 4000);
        } else {
          const albID = isAnExistingAlbum ? chooseExistingAlbum : albumId;
          setTimeout(() => { handleClose(); navigate(Pathname.purchasePlan('album', albID)); }, 4000);
        }
      } else {
        setaddMorePhoto(false);
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 200);
      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
      autoSignIn();
      setUploadContainer2((prevUploadContainer) => prevUploadContainer?.filter((id) => String(id) !== String(albumId)));
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setUploadContainer2((prevUploadContainer) => prevUploadContainer?.filter((id) => String(id) !== String(albumId)));
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong!!' });
      if (responseData?.message === 'Storage Space Exceeded') {
        handleClose();
        setTimeout(() => { navigate(Pathname.upgradePlan); }, 500);
      }
      setTimeout(() => {
        setVideoUploadPercentage(videoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setVideoUploadPercentage(100);
      }, 100);
      setTimeout(() => {
        setVideoUploadingCompleted(true);
      }, 1000);
      setTimeout(() => {
        setrunLoader(false);
      }, 5500);
    },
  });

  const { isValidating: gettingAlbumList, mutate: getAlbumsList } = useSWR(endpoints.getAlbumList, {
    onSuccess: ({ success, data }) => {
      if (success) {
        setAlbumList(data);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching your photo albums',
        });
      }
    },
    onError: () => {
      setAppSnackbar({
        isVisible: true,
        type: 'error',
        message: 'Oops! Something went wrong while fetching your photo albums'
      });
    },
  });
  const uploadPhotoToExistingAlbum = () => {
    const formData = new FormData();
    formData.append('album_id', chooseExistingAlbum);
    photoFile.forEach((photo) => {
      formData.append('photo_file[]', photo.file);
    });
    setAlbumUploadData(formData);
  };

  useEffect(() => {
    getAlbumsList();
  }, []);
  const getBase64FromFile = (inputFile) => {
    const fileReader = new FileReader();

    return new Promise((resolve) => {
      fileReader.onerror = () => {
        fileReader.abort();
        resolve('');
      };

      fileReader.onload = () => {
        resolve(fileReader.result.replace(/^data:.+;base64,/, ''));
      };
      fileReader.readAsDataURL(inputFile);
    });
  };

  const getBase64VideoThumbnails = async () => {
    let base64VideoThumbnails = '';

    return new Promise((resolve) => {
      videoThumbnails?.forEach(async (videoThumbnail, idx) => {
        const base64Thumbnail = await getBase64FromFile(videoThumbnail);

        base64VideoThumbnails = `${base64VideoThumbnails}${base64Thumbnail}`;

        if ((idx + 1) < videoThumbnails?.length) { base64VideoThumbnails = `${base64VideoThumbnails}, }`; }

        if ((idx + 1) === videoThumbnails?.length) { resolve(base64VideoThumbnails); }
      });
    });
  };

  // Creating payload for first addvideoCotnet api
  const uplodingVideo = () => {
    let uniqueKeyid;
    if (s3Data.get('draft_id')) {
      uniqueKeyid = s3Data.get('draft_id');
    } else {
      uniqueKeyid = uuid();
      let initialData;
      initialData.append('draft_id', uniqueKeyid);
      setS3Data(initialData);
    }
    setUniqueid(uniqueKeyid);
    const formData = new FormData();
    formData.append('status', 0);
    formData.append('video_file', videoFile);
    formData.append('draft_id', uniqueKeyid);
    setVideoUploadData(formData);
    // if (currentStep === 0) {
    //   setrunLoader(true);
    // }
  };

  function changeSize(size) {
    const newSize = size / 1024 / 1024;
    return newSize.toFixed(2);
  }

  const spinerSmall = 'spinex';
  const CustomCloseloader = () => (
    <button className={styles.closeMeBro}>
    <Spinner spinex={spinerSmall} />
    </button>
  );
  const updateDummy = (dummy, status = 0) => {
    if (status === 1) {
      dispatch(saveDummy({ [dummy?.draft_id]: dummy }));
    } else {
      dispatch(removeDummy(dummy));
    }
  };
  const handleEncouter = (draft_id) => {
    toast.update(draft_id, {
      closeButton: <CustomCloseloader />,
    });
    changeFieldFromFirebase(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 'DELETED');
    updateDummy(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 0);
    setIamUploading(false);
    updateUploadPercentages(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 'Cancelled');
    closeToast(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 'Upload has been canceled by user', 'error');
  };
  const backToOld = ({ draft_id }) => {
    toast.update(draft_id, {
      closeButton: <CustomCloseButton draft_id={draft_id} />,
    });
  };
  const CustomCloseDiv = (draft_id) => (
    <div className={styles.customDiv}>
      Are you sure you want to cancel ?
      <div className={styles.fleo}>
        <button className={styles.cancelPlz} onClick={() => backToOld(draft_id)}>
        Exit
        </button>
        <button className={styles.noCancel} onClick={() => handleEncouter(draft_id)}>
          Continue to cancel
        </button>
      </div>
    </div>
  );
  const handleClick = (draft_id) => {
    toast.update(draft_id, {
      closeButton: <CustomCloseDiv draft_id={draft_id} />,
    });
  };

  const CustomCloseButton = ({ draft_id }) => (
    <button className={styles.closeMeBro} onClick={() => handleClick(draft_id)}>
    <CloseIcon />
    </button>
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);
  // Create payload for save to draft api
  const handleSaveToDraft = () => {
    toast.update(uniqueid, { closeButton: <CustomCloseButton draft_id={uniqueid} /> });
    const modifiedArray = videoThumbnails.map((elem) => (elem.replace('data:image/png;base64,', '')));
    const formData = new FormData();
    if (uploadedData?.category_id && videoVisibility && defaultVideoThumbnail) {
      setUploadingInProgress({ ...uploadingInProgress, video: true });
    } else {
      setUploadingInProgress({ ...uploadingInProgress, draft: true });
    }
    // Append parameters to the FormData object
    // Required
    formData.append('draft_id', uniqueid);
    formData.append('draft_content_id', draftId);
    // Optional
    if (videoTitle === '' || videoTitle === undefined) {
      formData.append('title', fileName);
    } else {
      formData.append('title', videoTitle);
    }
        const video = document.getElementById('video-player');
    video.addEventListener('loadedmetadata', () => {
      formData.append('videoOreintationx', video.videoWidth);
    });
    const orientation = video.videoHeight < video.videoWidth ? 1 : 2;
    formData.append('videoOreintation', orientation);
    formData.append('videoHeight', video.videoHeight);
    formData.append('videoWidth', video.videoWidth);
    videoDescription && formData.append('description', videoDescription);
    videoVisibility && formData.append('visibility', videoVisibility);
    uploadedData?.playlist_id && formData.append('playlist_id', uploadedData?.playlist_id);
    uploadedData?.category_id && formData.append('category_id', uploadedData?.category_id);
    uploadedData?.subcategory_id && formData.append('subcategory_id', uploadedData?.subcategory_id);
    videoGroup && formData.append('group_id', videoGroup); // Gruop added to payload.
    videoThumbnails?.length && formData.append('thumbnail_list', modifiedArray.join('####'));
    videoThumbnails?.length && formData.append('thumbnail_selected', modifiedArray[0]);
    // videoDetails && videoDetails?.size && formData.append('video_size', fileSize(videoDetails?.size));
    videoDetails && videoDetails?.size && formData.append('video_size', changeSize(videoDetails?.size));
    videoDetails && videoDetails?.duration && formData.append('video_duration', videoDetails?.duration);
    setDraftCategoryState((prevDraftCategoryState) => ({
      ...prevDraftCategoryState,
      [uniqueid]: {
        title: videoTitle === '' || videoTitle === undefined ? fileName : videoTitle,
        category_id: uploadedData?.category_id,
        subcategory_id: uploadedData?.subcategory_id,
        thumbnail_list: defaultVideoThumbnail !== noPhotoAvailable && defaultVideoThumbnail && defaultVideoThumbnail !== generatingThumbnail ? blobFile : [],
        thumbnailx: defaultVideoThumbnail,
        customThumbnails: customVideoThumbnails,
        visibility: videoVisibility,
        description: videoDescription,
        videoHeight: video.videoHeight,
        videoWidth: video.videoWidth,
        videoOreintation: orientation,
        playlist_ids: uploadedData?.playlist_id,
        video_duration: videoDetails?.duration,
        group_ids: videoGroup,
        videoGroup
      },
    }));
    // setFormData(blobFile);
    blobFile.forEach((file, index) => {
      formData.append('thumbnails[]', file, `thumbnail_${index + 1}.jpg`);
  });
  if (defaultVideoThumbnail !== noPhotoAvailable && defaultVideoThumbnail && defaultVideoThumbnail !== generatingThumbnail) {
    (thumbnailType === 'normal') && formData.append('thumbnail', blobFile[0]);
    thumbnailType === 'custom' && formData.append('custom_thumbnails', defaultVideoThumbnail.split('base64,')[1]);
  }
    formData.append('status', 2);
    // setVideoUploadData(formData);
    let type_of;
    let contentStatus;
    if (uploadedData?.category_id && uploadedData?.category_id !== 'delete' && defaultVideoThumbnail !== noPhotoAvailable && defaultVideoThumbnail && defaultVideoThumbnail !== generatingThumbnail && videoVisibility) {
      type_of = 0;
      contentStatus = 'Video';
    } else {
      type_of = 2;
      contentStatus = 'Draft';
    }
    const currentDate = new Date();
    const date = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `${String(month).padStart(2, '0')}/${String(date).padStart(2, '0')}/${year}`;
    const thum = defaultVideoThumbnail !== noPhotoAvailable && defaultVideoThumbnail && defaultVideoThumbnail !== generatingThumbnail && defaultVideoThumbnail !== undefined ? `data:image/png;base64, ${defaultVideoThumbnail.split('base64,')[1]}` : null;
    const titleName = (videoTitle === '' || videoTitle === undefined) ? fileName : videoTitle;
    const dummy = {
      draft_id: uniqueid,
      title: titleName,
      thumbnail: thum,
      category: uploadedData?.category_name,
      video_description: videoDescription,
      created: formattedDate,
      video_size: changeSize(videoDetails?.size),
      type: type_of,
      group_id: uploadedData?.group_id,
      content_status: contentStatus,
      uploadIsTrying: 1
    };
    if (!uploadPercentages[uniqueid]) {
      updateUploadPercentages(uniqueid, 'Initializing');
    }
    updateDummy(dummy, 1);
    setDraftApiStatus('loading');
    setisAddDraftDone(true);

    // if (isS3UploadComplete[dummy?.draft_id] === true) {
    //   addVideo(formData).then((response) => {
    //     updateDummy(dummy?.draft_id, 0);
    //     toast.update(uniqueid, { closeButton: <CustomCloseButton draft_id={uniqueid} /> });
    //     setDraftApiStatus('success');
    //     setUploadingInProgress({});
    //     setisAddDraftDone(false);
    //   })
    //     .catch((error) => {
    //       setDraftApiStatus('error');
    //       // Handle errors
    //       console.error('Error adding video draft:', error);
    //     });
    // } else {
    dispatch(setAddVideo({ draftId: dummy?.draft_id, value: true }));
    // }
    handleClose();
    if (uploadedData?.category_id && videoVisibility && defaultVideoThumbnail !== noPhotoAvailable && defaultVideoThumbnail && defaultVideoThumbnail !== generatingThumbnail) {
      handleClose(SAVE_TYPE.PUBLISH);
    } else {
      handleClose(SAVE_TYPE.DRAFT);
    }
    // setTimeout(() => {
    //   setAppSnackbar({ isVisible: true, type: 'success', message: 'Video details updating ....' });
    // }, 2000);
  };

  const uploadVideo = async () => {
    const formData = new FormData();

    if (!videoIsUploaded) {
      setUploadedData({
        ...uploadedData,
        description: videoDescription,
        title: videoTitle,
        video_file: videoFile,
        thumbnails: videoThumbnails,
      });
      if (videoDescription) { formData.append('description', videoDescription); }
      if (videoTitle) { formData.append('title', videoTitle); }
      // if (videoThumbnails) { formData.append('thumbnails', await getBase64VideoThumbnails()); }
      if (videoThumbnails) {
        thumbnailType === 'normal' ? formData.append('thumbnails', defaultVideoThumbnail)
          : formData.append('custom_thumbnails', defaultVideoThumbnail.split('base64,')[1]);
      }
      formData.append('status', 0);
      formData.append('video_id', videoID);
      // formData.append('video_file', videoFile);
    } else {
      setUploadedData({
        ...uploadedData,
        ...videoThumbnails !== uploadedData.thumbnails && { thumbnails: videoThumbnails },
        ...videoDescription !== uploadedData.description && { description: videoDescription },
        ...videoTitle !== uploadedData.title && { title: videoTitle },
      });
      formData.append('video_id', videoID);
      // formData.append('video_id', videoID);

      if (videoThumbnails !== uploadedData.thumbnails) {
        thumbnailType === 'normal' ? formData.append('thumbnails', defaultVideoThumbnail)
          : formData.append('custom_thumbnails', defaultVideoThumbnail.split('base64,')[1]);
      }
      if (videoDescription !== uploadedData.description) {
        formData.append('description', videoDescription);
      }
      if (videoTitle !== uploadedData.title) {
        formData.append('title', videoTitle);
      }
    }
    setVideoUploadData(formData);
  };

  const updateVisibility = () => {
    const formData = new FormData();
    setUploadedData({
      ...uploadedData,
      ...videoVisibility !== uploadedData.visibility && { visibility: videoVisibility },
      ...(videoVisibility === '3') && { group_id: videoGroup },
    });
    formData.append('video_id', videoID);
    formData.append('visibility', videoVisibility);
    formData.append('status', 1);
    if (videoVisibility === '3') {
      formData.append('group_id', videoGroup);
    }
    setVideoVisibilityData(formData);
  };
  const convertFormDataToJson = (formData) => {
    const formDataJson = {};
    formData.forEach((value, key) => {
      formDataJson[key] = value;
    });
    const formDataJsonString = JSON.stringify(formDataJson);
    return formDataJsonString; // You can do whatever you want with the JSON data
  };
  const handleAddS3Video = () => {
    setIsSelectedFile(true);
    // addS3VideoDraftContent(s3Data).then((response) => {
    //   setButtonDisabled(false);
    //   if (!s3Data?.has('draft_content_id') && response?.data?.draft_content_id !== undefined) {
    //     setdraftId(response?.data?.draft_content_id);
    //     setUniqueid(s3Data.get('draft_id'));
    //     // updatedFormData.append('draft_content_id', response?.data?.draft_content_id);
    //     // setS3Data(updatedFormData);
    //   }
    // });
  };

  useEffect(() => {
    if (videoUploadData && currentStep < 2) {
      moveToNextStep();
      handleAddS3Video();
    }
  }, [videoUploadData]);

  // useEffect(() => {
  //   if (s3Data?.s3_url) {
  //     uploadS3Video();
  //   }
  // }, [s3Data]);

  const addOrEditAlbum = () => {
    setLoader(true);
    addEditAlbum();
  };

  const convertBase64 = (file) => new Promise((resolve, rej) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      rej(error);
    };
  });
  const [loading, setLoading] = useState(false);
useEffect(() => {
  settextOfUpload('');
}, []);

  const publishAlbum = async () => {
    setUploadContainer2((prevUploadContainer) => [...prevUploadContainer, String(albumId)]);
    settextOfUpload('Done');
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('album_id', albumId);
      formData.append('visibility', albumVisibility);
      if (albumVisibility === '3') {
        formData.append('group_id', videoGroup);
      }
      // photoFile.forEach((photo) => {
      //   formData.append('photo_file[]', photo.file);
      // });
      const base64 = await convertBase64(defaultAlbumThumbnail.file);
      // formData.append('icon', photoFile[0].file);
      formData.append('icon', base64);
      setAlbumUploadData(formData);
    } catch (error) {
      console.error('Error publishing album:', error);
    } finally {
      setLoading(false);
      // settextOfUpload('Done');
    }
  };

  useEffect(() => {
    if (albumUploadData) {
      if (subscriptionDetails?.has_activePlan) {
        setAlbumStatus('Album Published');
      } else {
        setAlbumStatus('Photo Uploaded');
      }
      setContentType('album');
      setAlbumPublishStatus(true);
      addPhotoContent();
    }
  }, [albumUploadData]);
  useEffect(() => {
    if (!videoUploadData && videoFile) { uplodingVideo(); }
  }, [videoFile]);
  useEffect(() => {
    if (videoVisibilityData && currentStep === 3) {
      if (!subscriptionDetails?.has_activePlan) {
        handleClose();
        navigate(Pathname.purchasePlan('video', videoID));
        // setAppSnackbar({ isVisible: true, type: 'error', message: 'You must have purchase a plan to upload a video' });
      } else {
        updateVisibilityContent();
      }
    }
  }, [videoVisibilityData]);

  useEffect(() => (() => {
    videoThumbnails.forEach((videoThumbnail) => { URL.revokeObjectURL(videoThumbnail); });
  }), []);

  useEffect(() => {
    if (videoVisibility === videoVisibilityOptions[2].value) {
      document.getElementById('group-selector').scrollIntoView();
    }
  }, [videoVisibility]);
  function base64ToFile(base64String, fileName, index) {
    const byteString = atob(base64String);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i += 1) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
    const file = new File([blob], fileName, { type: 'image/jpeg' });
    return file;
  }
  const [screenshotsArray, setScreenshotsArray] = useState([]);
  const addScreenshot = (image, backend) => {
    console.log('customVideoThumbnails', customVideoThumbnails);
    if (backend === 'backend' && defaultVideoThumbnail === noPhotoAvailable && customVideoThumbnails?.length === 0) {
      setDefaultVideoThumbnail(image);
    } else {
      setScreenshotsArray((prevScreenshots) => {
        const updatedScreenshots = [...prevScreenshots, image];
        // Set defaultVideoThumbnail only if it's currently set to noPhotoAvailable
        if (defaultVideoThumbnail === noPhotoAvailable && customVideoThumbnails?.length === 0) {
          setDefaultVideoThumbnail(updatedScreenshots[0]);
        }
        return updatedScreenshots;
      });
    }
  };
  const callBackendThumbnailAPI = (file) => {
    setDefaultVideoThumbnail(generatingThumbnail); // Set loader or placeholder thumbnail
    const videoFilePiece = new FormData();
    videoFilePiece.append('video_file', file.slice(0, 5 * 1024 * 1024)); // Sending first 5 MB of the video
    const apiUrl = 'https://conf.eq7.in/generate-thumbnail/';
    fetch(apiUrl, {
      method: 'POST',
      body: videoFilePiece,
    })
      .then((response) => {
        if (!response.ok) {
          // Log the error status and response text to understand the cause
          return response.text().then((text) => {
            console.error(`Error: ${response.status} - ${text}`);
            throw new Error(`Server error ${response.status}: ${text}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data?.thumbnail) {
          setIsThumbnailGenerationCompleteFully(true);
          // If backend successfully returns a thumbnail, update state
          setBlobFile([base64ToFile(data?.thumbnail, 'thumbnail_1.jpg')]);
          setVideoThumbnails([`data:image/png;base64,${data?.thumbnail}`]);
          !customAdded.current ? addScreenshot((`data:image/png;base64,${data?.thumbnail}`), 'backend') : null;
          // if (customVideoThumbnails?.length === 0) {
          //   setDefaultVideoThumbnail(`data:image/png;base64,${data?.thumbnail}`);
          // }
        } else {
          setIsThumbnailCreationFailed(true);
          // If no thumbnail from backend, set a default fallback image
          setIsThumbnailGenerationCompleteFully(true);
          setDefaultVideoThumbnail(noPhotoAvailable);
        }
      })
      .catch((error) => {
        setIsThumbnailCreationFailed(true);
        setIsThumbnailGenerationCompleteFully(true);
        setDefaultVideoThumbnail(noPhotoAvailable); // Set fallback thumbnail on error
        console.error('Error during thumbnail generation', error);
      });
  };

/* eslint-disable */
  const VideoFile = (e) => {
    const file = e;
    setFileName(e.name.split('.').slice(0, -1).join('.'));
    const fileReader = new FileReader();
    setVideoDetails({ name: file.name.split('.').slice(0, -1).join('.'), size: file.size });
    if (file.type.match('image')) {
      // Handle image file
      fileReader.onload = () => {
        setDefaultVideoThumbnail(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    } else {
      // Handle video file for thumbnail generation
      const video = document.getElementById('video-player');
      const screenshots = [];
      let num = 0;
      let thumbnailsGenerated = 0;
      let hasAddedScreenshot = false;
      const snapImage = () => {
        try {
          video.currentTime += video.duration / 8; // Move to the next time point in the video
          num = parseInt(video.currentTime, 10);
          // Create canvas and draw the current video frame
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const pixelData = imageData.data;
          // Check for transparency in the image
          const isTransparent = checkTransparency(pixelData);
          if (!isTransparent) {
            canvas.toBlob((blob) => {
              const thumbnailFile = new File([blob], `thumbnail_${screenshots.length + 1}.jpg`, { type: 'image/jpeg' });
              setBlobFile((prevBlobFiles) => [...prevBlobFiles, thumbnailFile]);
            });
            const image = canvas.toDataURL();
            if (!screenshots.includes(image)) {
              screenshots.push(image);
              setVideoThumbnails(screenshots);
              setIsThumbnailCreationFailed(true);
              if (!hasAddedScreenshot) {
                addScreenshot(image);
                hasAddedScreenshot = true; // Set the flag to true after the first call
              }
            }
          } else {
            callBackendThumbnailAPI(file);
          }
          thumbnailsGenerated++;
          if (thumbnailsGenerated < 6) {
            video.currentTime += video.duration / 8; // Move to the next time point in the video
          } else {
            video.removeEventListener('timeupdate', timeupdate); // Stop after 6 thumbnails
            setIsThumbnailGenerationCompleteFully(true);
          }
        } catch (error) {
          console.error('Error taking snapshot', error);
          callBackendThumbnailAPI(file);
          video.removeEventListener('timeupdate', timeupdate); // Stop on error
        }

        setVideoDetails({
          name: file.name.split('.').slice(0, -1).join('.'),
          size: file.size,
          duration: video.duration,
        });

        setS3VideoDetails({
          file_name: file.name.split('.').slice(0, -1).join('.'),
          size: file.size,
          videoDuration: video.duration,
        });
      };
      // Function to check if an image contains transparency
      const checkTransparency = (pixelData) => {
        const threshold = 0.1; // Set threshold for transparency (optional)
        let transparentPixels = 0;
        const totalPixels = pixelData.length / 4; // Each pixel is represented by 4 values (RGBA)
        for (let i = 3; i < pixelData.length; i += 4) {
          if (pixelData[i] === 0) { // Check if the alpha value is zero (fully transparent)
            transparentPixels++;
          }
        }
        const transparentRatio = transparentPixels / totalPixels;
        return transparentRatio > threshold; // Return true if a significant part of the image is transparent
      };
      const url = URL.createObjectURL(file);
      const timeupdate = () => {
        snapImage(); // Call the snapshot function on every timeupdate event
      };
      video.addEventListener('loadeddata', () => {
        snapImage(); // Start generating thumbnails when video is loaded
        setVideoDetails({ name: file.name.split('.').slice(0, -1).join('.'), size: file.size, duration: video.duration });
      });
      setTimeout(() => {
        setIsThumbnailGenerationCompleteFully(true);
      }, 40000);
      video.addEventListener('timeupdate', timeupdate); // Trigger image snapshot on timeupdate events
      video.preload = 'metadata';
      video.src = url;
      video.muted = true;
      video.playsInline = true;
      if (video.play) {
        video.play();
      }
      setCurrentStep(0);
      setVideoFile(file);
    }
  };
  /* eslint-enable */
  const PhotoFile = (e) => {
    const selectedFiles = imageFiles || e.target.files;
    setMultiplePhoto(selectedFiles);
    const photoData = [];
    let albSize = 0;
    if (selectedFiles) {
      if (imageFiles?.length > 0) {
        selectedFiles.forEach((file) => {
          photoData.push({ 'url': URL.createObjectURL(file), 'file': file });
          albSize += file.size;
        });
      } else {
        Array.from(selectedFiles).forEach((file) => {
          photoData.push({ 'url': URL.createObjectURL(file), 'file': file });
          albSize += file.size;
        });
      }
      setAlbumSize(albSize);
      const photoArray = [...photoFile];
      photoArray.push(...photoData);
      setPhotoFile(photoArray);
      setAlbumPhotoCount(photoArray?.length);
      if (imageFiles?.length > 0) {
        setUpdateType('photo');
      }
      setChooseAlbumStep(2);
    }
  };

  useEffect(() => {
    if (imageFiles?.length > 0) {
      PhotoFile();
    }
    setHoldTheWeb(true);
  }, []);
useEffect(() => {
  if (didIOpenModal === true) {
    handleClose();
  }
}, [didIOpenModal]);

  const removePhoto = (index) => {
    const photoArray = [...photoFile]; // make a separate copy of the array
    photoArray.splice(index, 1);
    setPhotoFile(photoArray);
  };

  const getRandomImages = () => {
    let randomArray = [];
    if (images.length > 3) {
      randomArray = images.sort(() => 0.5 - Math.random()).slice(0, 3);
    } else {
      randomArray = [...images];
    }
    setAlbumThumbnails(randomArray);
    setDefaultAlbumThumbnail(randomArray[0]);
    setChooseAlbumStep(3);
  };
  const [allowJpg, setallowJpg] = useState(false);
  const VideoThumbnails = async (e) => {
    const files = e?.target?.files;
    if (files.length === 0) {
      return; // No files selected, do nothing
    }
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
    if (!allowedTypes.includes(files[0].type)) {
      // setallowJpg(true);
      setAppSnackbar({ isVisible: true, message: 'Only allow png, jpg, webp, jpeg' });
      return;
    }
    const tmpVideoThumbnails = [];
    const file = files[0];
    const base64 = await convertBase64(file);
    tmpVideoThumbnails.push(base64);
    setDefaultVideoThumbnail(base64);
    setThumbnailType('custom');
    setIsThumbnailCreationFailed(false);
    setCustomVideoThumbnails(tmpVideoThumbnails);
    customAdded.current = true;
  };

  const generateCustomThumbnail = async (e) => {
    const files = e?.target?.files;
    const tmpAlbumThumbnails = [];
    const file = files[0];
    const base64 = await convertBase64(file);
    tmpAlbumThumbnails.push(base64);
    setDefaultAlbumThumbnail({ 'file': file, 'url': URL.createObjectURL(file) });
    setCustomAlbumThumbnails([{ 'file': file, 'url': URL.createObjectURL(file) }]);
  };

  const selectGroup = (id) => {
    if (videoGroup.includes(id)) {
      setVideoGroup(videoGroup.filter((item) => item !== id));
    } else {
      setVideoGroup([...videoGroup, id]);
    }
  };
const locationTracer = window.location.pathname;
const closeButton = () => {
  isSelectedFile ? deleteAdded() : null;
  if (locationTracer === '/dashboard') {
    navigate(Pathname.upgradePlan);
  }
  handleClose();
};

  return (
    <>
      <Fade in={isVisible} timeout={isVisible ? 300 : 250}>
        <div className={styles.body}>
          <video id="video-player" style={{ display: 'none' }} />
          <input
            accept="image/*"
            // disabled={uploadingVideo}
            id="video-thumbnails"
            multiple
            onChange={(e) => { VideoThumbnails(e); }}
            style={{ display: 'none' }}
            type="file"
          />

          <DialogTitle className={styles.header}>
            <div style={{ border: [1, 2, 3].includes(currentStep) ? 'none' : '' }} className={styles.headerContent}>
              <BackIcon className={styles.backButton} onClick={uploadingVideo ? undefined : handleClose} />
              <Text.PageTitle className={`${styles.headerText} ${videoDetails?.name && styles.headerTextSelectable}`}>
                {!videoIsPublished && !albumPublished
                  ? (currentStep || uploadType === 'video')
                    ? (videoTitle || videoDetails.name || 'Upload Video' || uploadText?.videoHeader)
                    : chooseAlbumStep ? (albumTitle || uploadText?.albumHeader || 'Upload Photo') : (uploadText?.header || 'Choose what to upload ')
                  : (videoIsPublished ? (uploadText?.videoPublished || 'Video Published') : ('Album Published'))}
              </Text.PageTitle>
              {draftApiStatus !== 'loading' && <CloseIcon className={styles.closeButton} onClick={closeButton} />}
            </div>
            {!videoIsPublished && (
              <>
                <ProgressTracker currentStep={currentStep} steps={[(uploadText?.detail || 'Details'), (uploadText?.categories || 'Categories'), (uploadText?.previousDetails || 'Previous Details')]} />
              </>
            )}
            {!videoIsPublished && !albumPublished && uploadType === 'photo' && (chooseAlbumStep === 3 || chooseAlbumStep === 4) && (
              <ProgressTracker currentStep={chooseAlbumStep === 3 ? 1 : 2} steps={[(uploadText?.detail || 'Details'), (uploadText?.summary || 'Summary')]} />
            )}
          <InfoModal
            isVisible={allowJpg}
            title="Only allows jpg, jpeg, webp and png"
            onHide={() => setallowJpg(false)}
            actionButton="Go back"
            fromPayment="true"
            action={() => setallowJpg(false)}
            processingInfo
          />
          </DialogTitle>

          {!currentStep
            ? (
              uploadType === 'video' ? (
                <VideoSelect fileInputRef={fileInputRef} handleClose={handleClose} VideoFile={VideoFile} uploadText={uploadText} onHide={handleClose} setUploadedFile={setUploadedFile} hideModal={handleClose} />
              ) : uploadType === 'photo' ? (
                chooseAlbumStep === 1 ? (
                  <PhotoSelect fileInputRef={fileInputRef} PhotoFile={PhotoFile} uploadText={uploadText} />
                ) : (
                  chooseAlbumStep === 2 ? (
                    <PhotoPreview
                      photoFile={photoFile}
                      photoUpload={PhotoFile}
                      removePhoto={removePhoto}
                      fileInputRef={fileInputRef}
                      handleClose={handleClose}
                      setChooseAlbumStep={setChooseAlbumStep}
                      fromContentLayout={isAnExistingAlbum}
                      uploadPhoto={uploadPhotoToExistingAlbum}
                      setaddMorePhoto={setaddMorePhoto}
                      addMorePhoto={addMorePhoto}
                      getRandomImages={getRandomImages}
                      addingPhotos={addingPhotos}
                      uploadText={uploadText}
                      commonText={commonText}
                    />
                  ) : (
                    chooseAlbumStep === 3 ? (
                      <AlbumDetails
                        spinerSmall={spinerSmall}
                        loader={loader}
                        setChooseAlbumStep={setChooseAlbumStep}
                        setAlbumTitle={setAlbumTitle}
                        albumTitle={albumTitle}
                        albumDescription={albumDescription}
                        setAlbumDescription={setAlbumDescription}
                        customAlbumThumbnails={customAlbumThumbnails}
                        defaultAlbumThumbnail={defaultAlbumThumbnail}
                        setDefaultAlbumThumbnail={setDefaultAlbumThumbnail}
                        generateCustomThumbnail={generateCustomThumbnail}
                        albumThumbnails={albumThumbnails}
                        addOrEditAlbum={addOrEditAlbum}
                        albumSize={albumSize}
                        albumPhotoCount={albumPhotoCount}
                        uploadText={uploadText}
                        commonText={commonText}
                      />
                    ) : chooseAlbumStep === 4 ? (
                      <AlbumSummary
                        videoVisibilityOptions={videoVisibilityOptions}
                        albumVisibility={albumVisibility}
                        setAlbumVisibility={setAlbumVisibility}
                        selectGroup={selectGroup}
                        settextOfUpload={settextOfUpload}
                        textOfUpload={textOfUpload}
                        videoGroup={videoGroup}
                        publishAlbum={publishAlbum}
                        defaultAlbumThumbnail={defaultAlbumThumbnail}
                        albumSize={albumSize}
                        albumPhotoCount={albumPhotoCount}
                        addingPhotos={addingPhotos}
                        uploadText={uploadText}
                        loading={loading}
                        commonText={commonText}
                        visibilityText={visibilityText}
                        setChooseAlbumStep={setChooseAlbumStep}
                        chooseAlbumStep={chooseAlbumStep}
                      />
                    ) : (
                      null
                    )
                  )
                )
              ) : (
                <ChooseWhatUpload
                  setUpdateType={setUpdateType}
                  setChooseAlbumStep={setChooseAlbumStep}
                  getAlbums={getAlbumsList}
                  albumList={albumList}
                  gettingAlbums={gettingAlbumList}
                  setExistingAlbum={setExistingAlbum}
                  chooseExistingAlbum={chooseExistingAlbum}
                  updateAnExistingAlbum={updateAnExistingAlbum}
                  uploadText={uploadText}
                  commonText={commonText}
                />
              )
            )
            : currentStep === 1
              ? (
                <VideoDetails
                 isThumbnailCreationCompleteFully={isThumbnailCreationCompleteFully}
                  buttonDisabled={buttonDisabled}
                  modalType="upload"
                  allowJpg={allowJpg}
                  setallowJpg={setallowJpg}
                  uploadText={uploadText}
                  videoTitle={videoTitle}
                  setVideoTitle={setVideoTitle}
                  draftApiStatus={draftApiStatus}
                  videoDescription={videoDescription}
                  setVideoDescription={setVideoDescription}
                  customVideoThumbnails={customVideoThumbnails}
                  setDefaultVideoThumbnail={setDefaultVideoThumbnail}
                  isThumbnailCreationFailed={isThumbnailCreationFailed}
                  setThumbnailType={setThumbnailType}
                  videoThumbnails={videoThumbnails}
                  thumbnailGenerationStatus={thumbnailGenerationStatus}
                  videoFile={videoFile}
                  uploadingVideo={uploadingVideo}
                  defaultVideoThumbnail={defaultVideoThumbnail}
                  videoDetails={videoDetails}
                  videoIsUploaded={videoIsUploaded}
                  uploadedData={uploadedData}
                  moveToNextStep={moveToNextStep}
                  uploadVideo={uploadVideo}
                  commonText={commonText}
                  conversionStart={conversionStart}
                  percentage={percentagee}
                  setUploadChecking={setUploadChecking}
                  handleSaveToDraft={handleSaveToDraft}
                  filename={videoDetails.name}
                  videoVisibility={videoVisibility}
                />
              )
              : currentStep === 2
                ? (
                  <>

                    <UpdateCategoryPlaylist
                      buttonDisabled={buttonDisabled}
                      conversionStatus={conversionStatus}
                      draftApiStatus={draftApiStatus}
                      uploadedData={uploadedData}
                      setUploadedData={(data) => setUploadedData(data)}
                      moveToNextStep={moveToNextStep}
                      moveToPreviousStep={moveToPreviousStep}
                      currentStep={currentStep}
                      videoID={videoID}
                      conversionStart={conversionStart}
                      uploadText={uploadText}
                      commonText={commonText}
                      percentage={percentagee}
                      uploadChecking={uploadChecking}
                      handleSaveToDraft={handleSaveToDraft}
                      videoTitle={videoTitle}
                      defaultVideoThumbnail={defaultVideoThumbnail}
                      videoVisibility={videoVisibility}
                    />
                  </>
                )
                : currentStep === 3
                  ? (
                    <VideoSummary
                      buttonDisabled={buttonDisabled}
                      modalType="upload"
                      draftApiStatus={draftApiStatus}
                      setVideoVisibility={setVideoVisibility}
                      videoVisibility={videoVisibility}
                      defaultVideoThumbnail={defaultVideoThumbnail}
                      videoDetails={videoDetails}
                      moveToPreviousStep={moveToPreviousStep}
                      updateVisibility={updateVisibility}
                      videoVisibilityOptions={videoVisibilityOptions}
                      videoGroup={videoGroup}
                      videoTitle={videoTitle || videoDetails.name}
                      isThumbnailCreationFailed={isThumbnailCreationFailed}
                      selectGroup={selectGroup}
                      uploadedData={uploadedData}
                      conversionStart={conversionStart}
                      percentage={percentagee}
                      handleSaveToDraft={handleSaveToDraft}
                    />
                  )
                  : null}
        </div>
      </Fade>
    </>

  );
};
export default Modal;
